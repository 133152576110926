<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="평가결과 환산표"
        tableId="table"
        :columns="grid.columns"
        :data="grid.data"
        :usePaging="false"
        :columnSetting="false"
        :gridHeight="gridheight"
      >
      </c-table>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'audit-result-table',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        sysRevision: '',
      }),
    },
  },
  data() {
    return {
      gridheight: '',
      grid: {
        columns: [
        ],
        data: [],
      },
      planData: {
        selfInspectionResultId: '',  // 자체감사 계획 일련번호
        selfInspectionTitle: '',  // 감사명
        selfInspectionYear: '',  // 감사년도
        selfInspectionTypeCd: null,  // 감사종류
        plantCd: null,  // 해당사업장
        remark: '',  // 감사대상 개요
        selfInspectionStartDt: '',  // 감사기간-시작일
        selfInspectionEndDt: '',  // 감사기간-종료일
        selfInspectionStatusCd: '',  // 감사진행상태-계획중,계획완료,감사완료
        selfInspectionResultOpinion: '',  // 감사원소견
        selfInspectionResultTotalOpinion: '',  // 감사 결과 종합의견
        selfInspectionResultCorrect: '',  // 시정 및 권고 요약
        selfInspectionDt: [],
        sysRevision: '',  // 평가항목 개정번호
        chgUserId: '',
        sysApprovalRequestId: '',
        approvalTypeCd: '',
        approvalStatusCd: '',
        approvalStatusName: '',
        processList: [],
        equipList: [],
        deptList: [],
        execList: [],
      },
      listUrl: '',
      deptArray: [],
      getUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.gridheight = (window.innerHeight - 208) + 'px';
      this.getUrl = selectConfig.sop.pca.result.info.get.url;
      this.listUrl = selectConfig.sop.pca.item.revs.result;
      this.getInfo();
    },
    getInfo() {
      this.$http.url = this.$format(this.getUrl, this.popupParam.selfInspectionResultId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.planData = _result.data;
        this.getList();
      },);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {sysRevision: this.popupParam.sysRevision, selfInspectionResultId: this.popupParam.selfInspectionResultId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        
        let deptScoreInfo = {}
        this.$_.forEach(this.planData.deptList, _dept => {
          deptScoreInfo['total_totalScore_' + _dept.deptCd] = 0;
          deptScoreInfo['total_score_' + _dept.deptCd] = 0;
        })

        let total_maxRealScoring = 0;
        let total_maxChangeScoring = 0;
        let total_totalScore = 0;
        let total_score = 0;
        this.$_.forEach(this.grid.data, item => {
          total_maxRealScoring += parseFloat(item.max_real_scoring ? item.max_real_scoring : '0');
          total_maxChangeScoring += parseFloat(item.max_change_scoring ? item.max_change_scoring : '0');
          total_totalScore += parseFloat(item.total_score ? item.total_score : '0');
          total_score += parseFloat(item.score ? item.score : '0');
          this.$_.forEach(this.planData.deptList, _dept => {
            deptScoreInfo['total_totalScore_' + _dept.deptCd] += parseFloat(item['totalScore_' + _dept.deptCd] ? item['totalScore_' + _dept.deptCd] :'0');
            deptScoreInfo['total_score_' + _dept.deptCd] += parseFloat(item['score_' + _dept.deptCd] ? item['score_' + _dept.deptCd] :'0');
          })
        });
        this.grid.data.push({
          item_mst_name: '합계',
          max_real_scoring: total_maxRealScoring,
          max_change_scoring: total_maxChangeScoring,
          total_score: total_totalScore,
          score: total_score.toFixed(2),
        });
        let totalData = this.grid.data[this.grid.data.length -1]
        this.$_.forEach(this.planData.deptList, _dept => {
          this.$set(totalData, 'totalScore_' + _dept.deptCd, deptScoreInfo['total_totalScore_' + _dept.deptCd].toFixed(2))
          this.$set(totalData, 'score_' + _dept.deptCd, deptScoreInfo['total_score_' + _dept.deptCd].toFixed(2))
        })
        this.setHeader();
      },);
    },
    setHeader() {
      this.grid.columns = [
        {
          name: 'item_mst_name',
          field: 'item_mst_name',
          label: '항목',
          align: 'left',
          sortable: false
        },
        {
          name: 'max_real_scoring',
          field: 'max_real_scoring',
          label: '최고실배점',
          align: 'center',
          style: 'width:200px',
          type: 'cost',
          sortable: false
        },
        {
          name: 'change_calculation',
          field: 'change_calculation',
          label: '환산계수',
          align: 'center',
          style: 'width:200px',
          sortable: false
        },
        {
          name: 'max_change_scoring',
          field: 'max_change_scoring',
          label: '최고환산점수',
          align: 'center',
          style: 'width:200px',
          sortable: false
        },
      ];
      
      this.$_.forEach(this.planData.deptList, _item => {
        this.grid.columns.push({
          label: _item.deptName,
          child: [
            {
              label: '실배점',
              name: 'totalScore_' + _item.deptCd,
              field: 'totalScore_' + _item.deptCd,
              align: 'right',
              sortable: false,
              style: 'width:80px',
              type: 'cost'
            },
            {
              name: 'score_' + _item.deptCd,
              field: 'score_' + _item.deptCd,
              label: '평가점수',
              align: 'right',
              color: 'blue',
              style: 'width:80px',
              sortable: false
            },
          ],
        })
      })

      this.grid.columns.push(
        {
          label: '평균',
          child: [
            {
              label: '실배점',
              name: 'total_score',
              field: 'total_score',
              align: 'right',
              sortable: false,
              style: 'width:80px',
              type: 'cost'
            },
            {
              name: 'score',
              field: 'score',
              label: '평가점수',
              align: 'center',
              color: 'blue',
              style: 'width:80px',
              sortable: false
            },
          ],
        },
      )
    },
  }
};
</script>
